module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-158872255-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Čukoláda","short_name":"Čukoláda","description":"Čukoláda je čokoládovňa a kaviareň s jedinečnou atmosférou, pre všetkých, ktorí sa nezabudli hrať, majú radi pekné veci a milujú čokoládu, ale aj pre tých, ktorí ešte jej tajomstvo a účinky neobjavili","lang":"sk","start_url":"/","background_color":"#ffffff","theme_color":"#532A12","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e151ed9d78c748954cb57b55b563c32a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
